import type { FaqItem, FaqBuItem, FaqClassic } from "~/models/Faq";

export const generateFaqJsonLd = (faqs: FaqItem[]) => {
  return JSON.stringify({
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: faqs.map((faq) => {
      return {
        "@type": "Question",
        name: faq.title,
        acceptedAnswer: {
          "@type": "Answer",
          text: sanitize(faq.content),
        },
      };
    }),
  });
};
export const generateFaqClassicJsonLd = (faqs: FaqClassic[]) => {
  return JSON.stringify({
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: faqs.map((faq) => {
      return {
        "@type": "Question",
        name: faq.question,
        acceptedAnswer: {
          "@type": "Answer",
          text: sanitize(faq.answer),
        },
      };
    }),
  });
};

export const generateFaqBuJsonLd = (faqs: FaqBuItem[]) => {
  return JSON.stringify({
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: faqs.map((faq) => {
      return {
        "@type": "Question",
        name: faq.title,
        acceptedAnswer: {
          "@type": "Answer",
          text: sanitize(faq.text),
        },
      };
    }),
  });
};
